import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "common/src/sections-gatsby/Navigation"
import BlogPage from "common/src/sections-gatsby/Blog-grid"
import Footer from "common/src/sections-gatsby/Footer-classic"

import { GlobalStyle } from "common/src/sections-gatsby/app.style"
import theme from "common/src/theme/blog/themeStyles"

import Pager from "../components/pager"
import ReactGA from 'react-ga';
ReactGA.initialize('UA-64452838-7');

const BlogGridPage = ({data, pageContext}) => {
  ReactGA.pageview("/blog")

  const imgBlog ="https://blog.almasuarez.com/wp-content/uploads/2018/02/DSC_0126.jpg"
  const desc = "Bienvenidos a mi blog, estas son mis opiniones personales y recomendaciones sobre tecnología y viajes"
  return(
      <ThemeProvider theme={theme}>
        <Layout>
          <GlobalStyle />
          <SEO title="Alma Blog" 
              image={imgBlog}
              description={desc}
          />
          <Navigation />
          <BlogPage data={data}/>
          <Pager pageContext={pageContext} />
          <Footer  data={data}/>
        </Layout>
      </ThemeProvider>    
  )

}
export default BlogGridPage


export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWordpressPost(
      skip: $skip 
      limit: $limit 
    ) 
    {
      edges {
        node {
          wordpress_id
          title
          excerpt
          slug
          date(formatString: "YYYY-MM-DD")
          categories {
            name
            slug
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`