import React from "react";
import { Link } from "gatsby";

import { IoIosArrowRoundForward } from "react-icons/io";
import { Container, Row, Col } from "common/src/reusecore/Layout";

import PageHeader from "common/src/reusecore/PageHeader";

import { BlogPageWrapper } from "./blogGrid.style";

const BlogPage = ({data}) => {
  return (
    <BlogPageWrapper>
      <PageHeader title="Bienvenido a mi Blog" author={{ name: "Alma", profile: "#" }} back="https://blog.almasuarez.com/wp-content/uploads/2018/02/DSC_0126.jpg"  />

      <div className="blog-page-wrapper">
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <div className="blog-grid-wrapper" style={{paddingTop: "60px"}}>
                <Row>
                    {data.allWordpressPost.edges.map(post => (
                      <Col xs={12} sm={6} key={post.node.wordpress_id}>
                        <div className="post-block">
                          <div className="post-thumb-block">
                            <Link className="anchor" to={"/"+post.node.slug}>
                              <img src={post.node.featured_media.source_url} alt="img" />
                            </Link>
                          </div>
                          <div className="post-content-block">
                            <div className="post-meta-block">
                              
                                Fecha de publicación
                              <span className="divider">/</span>
                              <span>{post.node.date}</span>
                            </div>
                            <Link to={"/"+post.node.slug}>
                              <h2 style={{color: "var(--textBanner)"}}
                                dangerouslySetInnerHTML={{ __html: post.node.title }}
                              >
                              </h2>
                            </Link>
                            <p style={{color: "var(--textNormal)"}}
                                dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                            >
                              
                            </p>
                            <Link className="readmore-btn" to={"/"+post.node.slug} style={{color: "var(--textBanner)"}}>
                              leer más <IoIosArrowRoundForward />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </BlogPageWrapper>
  );
};

export default BlogPage;
