import React from 'react';

import {Link} from "gatsby";

const Pager = ({pageContext}) => {

    const {previousPagePath,nextPagePath} = pageContext;

    

    return (
        <div style={{width: "100%", padding: "20px", margin: "0 auto",textAlign: "center"}}>
            <div className="Container__ContainerWrapper-loHaVm">
                {previousPagePath && (
                    <div className="next">
                        <span><Link to={previousPagePath}>Página anterior</Link></span>
                    </div>
                )}
                {nextPagePath && (
                    <div className="next">
                        <span><Link to={nextPagePath}>Página siguiente</Link></span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Pager;